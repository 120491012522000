<template>
    <div class="flex cursor-pointer items-center">
        <button
            type="button"
            class="inline-flex items-center justify-center gap-1 rounded-[100px] bg-white p-[5px] text-xs font-semibold uppercase text-[#212121] transition-all focus:shadow-none focus:outline-none lg:min-w-[61px] lg:px-[5px] lg:py-[3px]"
            :class="[{ 'flex-row-reverse': locale === 'en' }, className]"
            aria-label="language dropdown menu button"
            @click="switchLanguage"
        >
            <span :class="['flex-1 text-center', localeClass]">{{
                locale
            }}</span>
            <img
                class="h-6 w-6 object-contain"
                :src="`${staticUrl}/assets/img/icons/lang/${locale}.png`"
                alt="lang"
            />
        </button>
    </div>
</template>
<script setup>
const { locale, locales, setLocale } = useI18n()

const { className, localeClass } = defineProps({
    className: {
        type: String,
        default: '',
    },
    localeClass: {
        type: String,
        default: '',
    },
})
const staticUrl = useRuntimeConfig().public.staticUrl

const switchLanguage = async () => {
    const newLocale = locales.value.find(
        (item) => item.code !== locale.value
    ).code
    if (newLocale) {
        try {
            await $fetch('/lang', {
                method: 'GET',
                key: 'lang',
                query: { lang: newLocale },
            })
            await setLocale(newLocale)
        } catch (error) {
            console.error(error)
        }
    }
}
</script>
